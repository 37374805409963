body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  box-sizing: border-box!important;
}
ul {
  padding: 0;
  margin: 24px 0;
}
li {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 4px 0;
}
li svg {
  height: 15px!important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.color-primary {
  color: #3DB5B9!important;
}
.color {
  color: white!important;
}
.btn-generic {
  border-radius: 12px!important;
}
.color-text {
  background-color: #FFDBDF;
  border-radius: 6px;
  padding: 0 16px;
}
.center {
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
}
.content-center-home {
  max-width: 1100px;
}
.section-full {
  width: 100vw;
  height: 100vh;
}
.tabs-year-time {
  padding: 30px 0;
}
.background-img {
  background-image: url('../assets/background.jpg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.flex-center-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center-row-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-start-row-mobile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-left-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.ma-l20 {
  margin-left: 20px!important;
}
.pad-section-34{
  padding: 34px 0
}
.info {
  background-color: #F1F1F1;
}
.planes {
  padding: 48px 0;
}
.left {
  width: 40%;
  height: 100vh;
  padding: 32px;
}
.right {
  width: 60%;
  height: 100vh;
}
.footer-flex {
  display: flex;
  justify-content: space-between;
}
.left-footer {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: flex-start;
  width: 50%;
  height: 100%;
  padding: 32px;
  box-sizing: border-box;
}
.right-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: center;
  width: 50%;
  height: 100%;
  padding: 32px;
  box-sizing: border-box;
}
.items {
  display: flex;
  margin: 0;
}
.social-media {
  display: flex;
  margin: 0;
}
.social-media li {
  margin: 10px;
}
.terms-list li {
  margin: 5px 0;
  line-height: 24px;
}
.background-img-info {
  background-image: url('../assets/image-dispositivos.png');
  background-position: center right;
  background-size: 800px;
  background-repeat: no-repeat;
}
.background-img-contacto {
  background-image: url('../assets/image-contacto.png');
  background-position: center right;
  background-size: 600px;
  background-repeat: no-repeat;
}
.card {
  width: 100%;
  max-width: 355px;
  box-sizing: border-box!important;
  min-height: 500px;
  border-radius: 20px;
  padding: 46px 24px;
  background-color: #E8F3F4;
/*   box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1); */
}
.card:hover {
  background-color: white;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
}
.slick-list {
  box-sizing: border-box;
  padding: 30px 5px!important;
}
.card-full {
  width: 100%;
  box-sizing: border-box!important;
  min-height: 370px;
  border-radius: 20px;
  margin-top: 24px;
  border: 3px solid #3DB5B9;
  padding: 40px 34px;
  background-image: url('../assets/image-perspectiva.png');
  background-position: top right;
  background-size: 630px;
  background-repeat: no-repeat;
  background-color: #E8F3F4;
  box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
}
.margin-item {
  margin: 0 10px;
}
.bg-color {
  background-color: #3DB5B9;
}
.bg-btn {
  background-color: white!important;
  color: black;
}
.card-info {
  width: 100%;
  max-width: 40%;
}
.bg-gray {
  background-color: #F1F1F1;
}
@media (max-width: 768px) {
  .footer-flex {
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
  }
  .left-footer {
    align-items: center;
    width: 100%;
    padding: 0;
  }
  .right-footer {
    align-items: center;
    width: 100%;
    padding: 0;
  }
  .flex-center-row-mobile {
    flex-direction: column;
  }
  .padding-mobile {
    padding: 12px;
    box-sizing: border-box;
  }
  .left {
    width: 100%;
  }
  .mobile {
    display: none;
  }
  .right {
    width: 100%;
  }
  .card {
    max-width: 100%;
  }
  .section-full {
    width: 100%;
  }
  .card-full {
    background-image: none;
  }
  .card-full button {
    width: 100%;
    margin: 0!important;
    margin-top: 10px!important;
  }
  .card-info {
    max-width: 100%;
  }
}

/* Loader personalizado */
.loading-circle {
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: -125px;
  }
}

/* Separador SVG */
.svg-separator {
  width: 100%;
  height: 150px;
  fill: #FFFFFF;
}

.section-separator {
  position: relative;
  background-color: #fff;
}

.section-separator .svg-separator {
  position: absolute;
  bottom: 0;
  left: 0;
}

/* Animaciones en los botones */
.btn-animated {
  position: relative;
  overflow: hidden;
}

.btn-animated::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.1);
  z-index: 0;
  transition: all 0.5s ease;
  transform: translateX(-75%) translateY(-75%) rotate(45deg);
}

.btn-animated:hover::before {
  transform: translateX(0) translateY(0) rotate(45deg);
}

/* Hover en las tarjetas */
.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
